.wrap-modal {
  width: 100% !important;
  max-width: 1024px;
  padding: 0 20px;
  position: relative;
  .header-modal {
    border-bottom: 1px solid #eeee;
  }
  .content-modal {
    padding: 20px 10px 10px 10px;
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 10px;
  }
  .container-modal {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .header-modal {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .action-modal {
    display: flex;
    .btn-close {
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
      transition: 0.5 all;
      transform: scale(1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
