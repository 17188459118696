.table-wrap {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  min-height: calc(100% - 64px);
}
.wrap-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.custom-form {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item-label {
    text-align: start;
    label {
      &::after {
        content: '';
      }
    }
  }
}

.box-wrap.custom-col {
  border-radius: 5px;
  .ant-col {
    min-width: 30%;
  }
  .ant-picker {
    width: 100%;
  }
}

.table {
  .group-action {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
  .image-col {
    max-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
}
.wrap-col {
  .ant-table-tbody > tr > td {
    white-space: pre-wrap;
  }
  .ant-table-thead > tr > th {
    white-space: pre-wrap;
  }
}

.screen-option {
  height: 100%;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    h2 {
      margin: 0;
    }
  }
  .btn-back {
    background-color: transparent;
    font-size: 18px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.5s;
    &:hover {
      opacity: 0.8;
      transform: translateX(-10px);
    }
  }
  .body-screen {
    padding: 10px;
    height: calc(100% - 54px);
    display: flex;
    align-items: center;
  }
}

.form-user {
  margin: 0 auto;
  .ant-form-item-control {
    max-width: 100%;
  }
}
