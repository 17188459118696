.table-wrap {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  min-height: calc(100% - 64px);
}
.wrap-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.name-column {
  background-color: #48b0f7;
  color: white;
  padding: 5px;
  border-radius: 5px;
  min-width: 80%;
  display: flex;
  justify-content: space-between;
  // padding: 0 5px;
  align-items: center;
}

.custom-form {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item-label {
    text-align: start;
    label {
      &::after {
        content: '';
      }
    }
  }
}

.box-wrap.custom-col {
  border-radius: 5px;
  .ant-col {
    min-width: 30%;
  }
  .ant-picker {
    width: 100%;
  }
}

.table {
  .group-action {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
  .status-on {
    background-color: white;
    color: #73d13d;
    padding: 2px 5px;
    font-size: 18px;
    // border: 1px solid #73d13d;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 15px -3px rgba(115, 209, 61, 0.8);
    }
  }

  .status-off {
    background-color: white;
    // border: 1px solid #f5222d;
    font-size: 18px;
    color: #f5222d;
    padding: 2px 5px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 15px -3px rgba(245, 34, 45, 0.8);
    }
  }
}

.screen-option {
  height: 100%;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    h2 {
      margin: 0;
    }
  }
  .btn-back {
    background-color: transparent;
    font-size: 18px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.5s;
    &:hover {
      opacity: 0.8;
      transform: translateX(-10px);
    }
  }
  .body-screen {
    padding: 10px;
    height: calc(100% - 54px);
    display: flex;
    align-items: center;
  }
}

.form-user {
  margin: 0 auto;
  .ant-form-item-control {
    max-width: 100%;
  }
}

.date-custom {
  .rs-btn {
    border-radius: 0;
  }
  .rs-picker-default .rs-picker-toggle.rs-btn {
    padding-bottom: 9.5px;
    padding-top: 9.5px;
  }
  .rs-stack-item {
    display: flex;
  }
}

.rs-stack-item {
  display: flex;
  .rs-picker-daterange-content {
    order: 2;
  }
  .rs-picker-toolbar {
    order: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    .rs-picker-toolbar-ranges {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
  .rs-picker-daterange-header {
    border-bottom: none;
  }
  .rs-picker-toolbar-right {
    padding-left: 10px;
    button {
      min-width: 80px;
    }
  }
}
