.form-event {
  .ant-form-item-label {
    width: 200px !important;
    min-width: unset !important;
    max-width: unset !important;
    font-weight: 600;
    flex: none;
  }
}
form {
  .ant-form-item-row {
    .ant-form-item-control {
      // max-width: 400px !important;
    }
  }
  .full-size {
    .ant-form-item-row {
      .ant-form-item-control {
        max-width: 100% !important;
      }
    }
  }
}
