.upload-img {
  display: none !important;
}
.btn-upload {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  height: 100px;
  border: 1px dashed #ccc;
}

.wrap-img {
  width: 100px;
  height: 100px;
  position: relative;
  display: flex;
  // align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    max-width: 100%;
    object-fit: scale-down;
  }
  .action-img {
    opacity: 0;
    transition: all 0.5s;
    width: 0%;
    height: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($color: #000000, $alpha: 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 10px;
  }
  .btn-change,
  .btn-delete {
    color: white;
    font-size: 20px;
    font-family: bold;
    display: block;
    cursor: pointer;
  }
  &:hover {
    .action-img {
      opacity: 1;
      background-color: rgba($color: #000000, $alpha: 0.5);
      width: 100%;
      height: 100%;
    }
  }
}

.ant-upload-picture-card-wrapper {
  .ant-tooltip-inner {
    display: none !important;
  }
}

.file-notify {
  width: 50px;
  img {
    max-width: 100%;
  }
}
