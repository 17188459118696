.btn-login {
  .ant-form-item-control {
    max-width: 100%;
  }
  button {
    width: 100%;
  }
}

.title-login{
  background-color: white;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.img-logo-title{
  width: 30px;
  height: auto;
}
