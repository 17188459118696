.img-item {
  max-width: 30px;
  img {
    max-width: 100%;
  }
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.list-social {
  display: flex;
  gap: 10px;
}

.filed-item {
  display: flex;
  gap: 20px;
  width: 100%;
  .title {
    font-size: 15px;
    min-width: 120px;
    text-align: end;
    font-size: 16px;
    font-weight: 600;
  }
  .value {
    padding: 5px 10px;
    background-color: #eeeeee;
    display: block;
    width: calc(100% - 120px);
  }
}

.body-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .group-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
}
