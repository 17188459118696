.wrapper {
  height: 100vh;
  width: 100%;
  // background-image: url("../../../assets/images/bg_dot.png");
  background-color: white;
  background-repeat: repeat;
  &--box {
    &--login {
      height: 100vh;
    }
  }
  .ant-layout-content {
    // background-color: rgba($color: #000000, $alpha: 0.05);
  }
  .ant-card {
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.3);
  }
}

.login-container {
  width: 500px;
  margin: 0 auto;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 436px;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
  }
}
