.screen {
  height: 100%;
  animation: showScreen 0.8s linear;
  padding: 10px;
  position: relative;
}

@keyframes showScreen {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
