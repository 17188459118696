.collapse-wrapper {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12);
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
  min-height: calc(100% - 64px);
  .tool-bar__left {
    margin-bottom: 25px;
  }
}
.wrap-pagination {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.custom-form {
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item-row {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item-label {
    text-align: start;
    label {
      &::after {
        content: '';
      }
    }
  }
}

.box-wrap.custom-col {
  border-radius: 5px;
  .ant-col {
    min-width: 30%;
  }
  .ant-picker {
    width: 100%;
  }
}

.table {
  .group-action {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
  }
}

.screen-option {
  height: 100%;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    h2 {
      margin: 0;
    }
  }
  .btn-back {
    background-color: transparent;
    font-size: 18px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 0.5s;
    &:hover {
      opacity: 0.8;
      transform: translateX(-10px);
    }
  }
  .body-screen {
    padding: 10px;
    height: calc(100% - 54px);
    display: flex;
    align-items: center;
  }
}

.form-user {
  margin: 0 auto;
  .ant-form-item-control {
    max-width: 100%;
  }
}

.header-collapse {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title-collapse {
    font-weight: 600;
    font-size: 16px;
  }
  .action-collapse {
    display: flex;
    gap: 10px;
  }
}

.role-level-1,
.role-level-2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 10px;
}
.list-role {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    gap: 10px;
  }
  .ant-collapse-content-box {
    // background-color: #48b0f7;
  }
  .title-level-1 {
    // background-color: #2ca7d0;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    box-shadow: 0px 0px 4px -3px rgba(0, 0, 0, 0.1);
    // border-radius: 5px;
    border: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9 !important;
    overflow: hidden;
    .ant-collapse-header {
      background-color: #f1f1f1;

      // color: white !important;
    }
    .role-level-1 {
      // color: white;
    }
    // .ant-checkbox-inner {
    //   background-color: white;
    //   border-color: #008000;
    // }
    // .ant-checkbox-checked .ant-checkbox-inner {
    //   background-color: #008000;
    //   border-color: #008000;
    // }
  }

  .title-level-2 {
    box-shadow: 0px 0px 4px -3px rgba(0, 0, 0, 0.1);
    border: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9 !important;
    margin-top: 10px;
  }
}
