.table-wrap {
  .title {
    font-size: 25px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
}
.container-dashboard {
  width: 100%;
}
.header-dashboard {
  width: 100%;
}
.statistical {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  &__item {
    width: calc(25% - 7.5px);
    min-height: 150px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 0px 15px 8px rgba(0, 0, 0, 0.1);
    color: white;
    border-radius: 10px;
    @media screen and (max-width: 768px) {
      width: calc(50% - 10px);
    }
    .title {
      font-size: 20px;
      border: none;
      margin: 0;
      text-align: center;
    }
    .value {
      font-size: 50px;
      margin: 0;
    }
  }
  .item-w-50 {
    width: calc(50% - 10px);
  }
  .color-1 {
    background-color: #33cabb;
  }
  .color-2 {
    background-color: #f96868;
  }
  .color-3 {
    background-color: #15c377;
  }
  .color-4 {
    background-color: #926dde;
  }
  .color-5 {
    background-color: #d3b068;
  }
}
