.form-role {
  .ant-form-item-label {
    width: 200px !important;
    min-width: unset !important;
    max-width: unset !important;
    font-weight: 600;
    flex: none;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
