.layout-default {
	min-height: 100vh;
	height: auto;
}

.background-content {
	background-color: #f5f6fa !important;
}

html body.swal2-height-auto {
	height: 100% !important;
}

.header-wrap {
	display: flex;
	align-items: center;
	gap: 15px;
	padding: 10px;
	color: black;
}

.layout-header {
	position: sticky;
	top: 0;
	z-index: 1;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
}

.layout-header-collapsed {
	display: flex;
	justify-content: space-between;
	width: calc(100% - 0px);
	z-index: 1000;
}
.ant-layout-header {
	display: flex;
	padding: 0;
}

.profile {
	width: auto;
	display: flex;
	.infor {
		color: black;
	}
}
.wrap-sider.ant-layout-sider {
	// max-width: 400px !important;
	// width: 250px !important;
}
.ant-layout {
	// .ant-layout-content {
	//   box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.4);
	//   border-radius: 7px;
	//   background-color: white;
	//   margin: 20px 20px;
	// }
	.title {
		border-bottom: 1px solid #ccc;
	}

	.form-upload {
		border: 2px solid #ececec;
		border-radius: 7px;
		background-color: white;
		width: 50%;
		max-width: 500px;
		min-width: 300px;
		margin: 0 auto;
		padding: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.m-top {
	margin-top: 20px;
}

.custom-size {
	margin-left: 250px;
	transition: all 0.2s;
}

.sider-fix {
	margin-left: 0;
}
.custom-size-collapsed {
	margin-left: 60px;
	transition: all 0.2s;
}
.ant-table-wrapper .ant-table-container {
	&::after,
	&::before {
		box-shadow: none !important;
	}
}

.ant-table-thead > tr > th {
	white-space: nowrap;
	word-break: break-word;
	word-break: break-all;
	vertical-align: baseline !important;
}
.ant-table-tbody > tr > td {
	white-space: nowrap;
	word-break: break-word;
	word-break: break-all;
}
.ant-layout-content {
	background-color: white;
}

.title-logo {
	color: white;
	margin-bottom: 0;
	height: 64px;
	display: flex;
	padding-left: 10px;
	justify-content: flex-start;
	align-items: center;
	a {
		display: block;
		overflow: hidden;
	}
	img {
		max-width: 100%;
		// max-width: 40px;
		// min-width: 250px;
		height: auto;
	}
}

.profile {
	display: flex;
	align-items: center;
	gap: 10px;
}
.drop-item {
	display: flex;
	align-items: center;
	gap: 10px;
	width: 100%;
}
.box-wrap {
	box-shadow: 0px 0px 10px rgb(0 0 0 / 12%);
	padding: 10px;
	margin-top: 10px;
}

.site-layout-background {
	background-color: white !important;
}

.wrapper-sider {
	display: flex;
	flex-direction: column;
}
.wrap-sider {
	.ant-menu-item {
		font-weight: 600;
	}
	.ant-menu-submenu {
		.ant-menu-title-content {
			font-weight: 600;
		}
	}
	.ant-menu-item a:hover,
	.ant-menu-light .ant-menu-item-active,
	.ant-menu-light .ant-menu-item:hover,
	.ant-menu-light .ant-menu-submenu-active,
	.ant-menu-light .ant-menu-submenu-title:hover,
	.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
		color: #33cabb;
		font-weight: 600;
	}
	.ant-menu-submenu-selected {
		color: #33cabb;
		font-weight: 600;
	}

	.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
	.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon {
		color: #33cabb;
		font-weight: 600;
	}
}

.notify {
	display: flex;
}

.item-notify {
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	min-width: 250px !important;
	border-bottom: 1px solid #ccc;
	padding: 10px 0;
	.notify-left {
		font-size: 25px;
	}
	.notify-rigth {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.content-notify {
			font-weight: 600;
		}
	}
}

.tool-bar {
	width: 100%;
	display: flex;
	justify-content: space-between;
	&__left {
		.btn-add {
			background-color: #33cabb;
			color: white;
			border: #33cabb;
			padding: 10px 10px;
			border-radius: 5px;
			transition: all 0.5s;
			cursor: pointer;
			&:hover {
				opacity: 0.7;
			}
			strong {
				margin-left: 5px;
			}
		}
	}
}

.box-wrap.custom-col {
	border-radius: 5px;
	.ant-col {
		min-width: 30%;
	}
	.ant-picker {
		width: 100%;
	}
	.ant-form-item {
		margin-bottom: 0;
	}
}

.search-group {
	.row {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		.col {
			min-width: 300px;
		}
		@media screen and (max-width: 768px) {
			.col {
				min-width: unset;
			}
			.ant-form-item .ant-form-item-row {
				flex-direction: column;
				align-items: flex-start;
			}
		}
	}
}

.title-notify {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	background-color: #33cabb !important;
	color: white;
	padding: 5px;
	font-size: 20px;
}

.notify-wapper {
	border-radius: 0 0 10px 10px;
	overflow: hidden;

	background-color: #fff;
	box-shadow:
		0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08),
		0 9px 28px 8px rgba(0, 0, 0, 0.05);
	.ant-dropdown-menu {
		box-shadow: none !important;
	}
	.view-all {
		padding: 0 5px 10px 5px;
		font-size: 16px;
		width: 100%;
		text-align: center;
		cursor: pointer;
		text-decoration: solid;
		transition: all 0.1s;
		box-sizing: border-box;
		background-color: #ccc;
		&:hover {
			color: #33cabb;
		}
	}
}
.status-col {
	color: white;
}

.btn-hover {
	opacity: 1;
	transition: all 0.2s;
	&:hover {
		opacity: 0.75;
	}
}

.btn-disable {
	pointer-events: none !important;
	color: rgba(0, 0, 0, 0.25) !important;
	border-color: #d9d9d9 !important;
	background-color: #f5f5f5 !important;
	text-shadow: none !important;
	box-shadow: none !important;
	border: none !important;
	cursor: not-allowed !important;
}
.form-col {
	@media screen and (max-width: 768px) {
		.ant-row {
			flex-direction: column;
			margin: 0 !important;

			.ant-col {
				width: 100% !important;
				max-width: 100% !important;
			}
			.ant-form-item-label {
				text-align: start !important;
			}
		}
	}
}

.hide-lable {
	@media screen and (max-width: 576px) {
		.ant-form-item-label {
			display: none;
		}
	}
}

.box-wrap.custom-m-x {
	background-color: white;
	.ant-col {
		// margin-right: 10px;
	}
}
.name-column {
	background-color: #48b0f7;
	color: white;
	padding: 5px;
	border-radius: 5px;
	.content-drop {
		white-space: nowrap;
		width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
a:active,
a:focus,
a:hover {
	text-decoration: none;
}

.list-notify {
	display: flex;
	flex-direction: column;
	max-height: 400px;
	overflow: auto;
}

.sound-active {
	cursor: pointer;
}

.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
	top: -5px;
}
